import React, { Component, lazy, Suspense } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { PATH } from "./utils/pagePath";
import PublicRoute from "./components/PublicRoute/PublicRoute";
import PrivateRoute from "components/ProtectedRoute/PrivateRoute";
import PreLogin from "./layouts/PreLogin/PreLogin";
import { CREATE_PROJECTS } from "services/endPoints";
import Loader from "components/loader/Loader";
const LazyCreateProject = lazy(() => import("pages/Project/CreateProject"));
const LazyBacklog = lazy(() => import("pages/Backlog/Backlog"));
const LazyActiveSprint = lazy(() => import("pages/ActiveSprint/ActiveSprint"));
const LazyCode = lazy(() => import("pages/Code/Code "));
const LazyProjectSetting = lazy(() => import("pages/ProjectSetting/ProjectSetting"));
const LazyProjects = lazy(() => import("pages/Project/Projects"));
const LazyRegister = lazy(() => import("pages/Register/Register"));
const LazySetPassword = lazy(() => import("pages/SetPassword/SetPassword"));
const LazyPostLogin = lazy(() => import("./layouts/PostLogin/PostLogin"));
const LazyLogin = lazy(() => import("./pages/Login/Login"));
const LazyInvitation = lazy(() => import("./pages/Invitation/Invitaion"));
const LazyInvitationNew = lazy(() => import("./pages/SetPassword/SetPassword"));
const LazyForgotPassword = lazy(() => import("./pages/ForgotPassword/ForgotPassword"));
const LazyOtpVerification = lazy(() => import('./pages/OtpVerification/OtpVerification'));
const LazyConfirmPassword = lazy(() => import('./pages/ConfirmPassword/ConfirmPassword'));
const LazyViewProfile = lazy(() => import("pages/Profile/ViewProfile"));
const LazyActiveSprintDetails = lazy(() => import("pages/ActiveSprint/ActiveSprintDetails"));
const LazyRoles = lazy(() => import("pages/Roles/Roles"));
const LazyUpdateTeamInfo = lazy(() => import("pages/CreateTeam/UpdateTeamInfo"));
const ViewAllIssues = lazy(() => import("pages/Filters/viewAllIssues"));
const ErrorPage = lazy(() => import("components/ErrorPage/ErrorPage"));

export const router = createBrowserRouter([
  {
    path: PATH.DEFAULT,
    element: <Navigate to={PATH.LOGIN} replace />,
  },
  {
    element: (
      <PublicRoute component={<PreLogin />}>
        {/* <LazyPreLogin /> */}
      </PublicRoute>
    ),
    children: [
      {
        path: PATH.LOGIN,
        element: <LazyLogin />,
      },
      {
        path: PATH.FORGOT_PASSWORD,
        element: <LazyForgotPassword />,
      },
      {
        path: PATH.REGISTER,
        element: <LazyRegister />,
      },
      {
        path: PATH.OTP_VERIFICATION,
        element: <LazyOtpVerification />,
      },
      {
        path: PATH.CONFIRM_PASSWORD,
        element: <LazyConfirmPassword />,
      },
      // {
      //   path: PATH.INVITATION,
      //   element: <LazyInvitation />,
      // },
      {
        path: PATH.INVITATION,
        element: <LazyInvitationNew />,
      },
      {
        path: PATH.SET_PASSWORD,
        element: <LazySetPassword />,
      },
    ],
  },
  {
    element: (
      <PrivateRoute component={<LazyPostLogin />}>
        {/* <LazyPostLogin /> */}
      </PrivateRoute>
    ),
    children: [
      {
        path: PATH.PROJECT_PLANNING,
        children: [
          {
            path: PATH.BACKLOG,
            element: <LazyBacklog />,
          },
          {
            path: PATH.ACTIVE_SPRINT,
            element: <LazyActiveSprint />,
          },
          {
            path: `${PATH.ACTIVE_SPRINT_DETAILS}/:projectId/:issueId`,
            element: <LazyActiveSprintDetails />,
          },
        ],
      },
      {
        path: PATH.DEVELOPMENT,
        children: [
          {
            path: PATH.CODE,
            element: <LazyCode />,
          },
        ],
      },
      {
        path: PATH.SETTINGS,
        children: [
          {
            path: PATH.ROLES,
            element: <LazyRoles />,
          },
        ],
      },
      {
        path: PATH.PROJECT_SETTINGS,
        element: <LazyProjectSetting />
      },
      {
        path: PATH.PROJECTS,
        children: [
          // {
          //     path: PATH.CREATE_PROJECTS,
          //     element: <LazyCreateProject />
          // },
          {
            path: PATH.PROJECTS,
            element: <LazyProjects />,
          },
          {
            path: `${PATH.PROJECTS}/teams/:id`,
            element: <LazyUpdateTeamInfo />,
          },
          {
            path: `${PATH.PROJECTS}/filter/view-all-issues`,  // New route
            element: <ViewAllIssues />,
          },
        ]
      },
      {
        path: PATH.PROFILE_VIEWPROFILE,
        children: [
          {
            path: PATH.PROFILE_VIEWPROFILE,
            element: <LazyViewProfile />
          }
        ]
      },
      // {
      //   path: `${PATH.TEAMS}/:id` ,
      //   element: <UpdateTeamInfo/>,
      // },
    ],
  },
  {
    path: CREATE_PROJECTS,
    element: <LazyCreateProject />,
  },
  {
    path:`${PATH.ERRORPAGE}/:id`,
    element:  < ErrorPage/>,
  }

]);
