import React, { memo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken } from 'utils/utils';

const PrivateRoute = ({ component: Component }) => {
    const location = useLocation();
    return (
        getToken() 
            ? Component 
            : <Navigate to={`/login?redirectTo=${encodeURIComponent(location.pathname)}`} />
    );
}

export default memo(PrivateRoute);
