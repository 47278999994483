import React from "react";
import styles from "./Loader.module.scss";

const Loader = () => {
  return (
    <div
    style={{
      width: "100vw",
      height: "100vh",
      // background: "transparent",
      background: "rgba(0, 0, 0, 0.6)",  // Semi-transparent black

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backdropFilter: "blur(5px)",
      opacity: "0.6",
      position: "fixed",  // Make sure it's fixed to the screen
      top: 0,
      left: 0,
      zIndex: 1000 , // Ensure it's above other content
      transition: "opacity 1s ease", // Smooth transition for 1 second

    }} >
      <span class={styles.loader}></span>
    </div>
  );
};

export default Loader;
