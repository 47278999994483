import globalSlice from "./slices/globalSlice";
import issueSlice from "./slices/issueSlice";
import projectSlice from "./slices/projectSlice";
import activeSprintSlice from "./slices/activeSprintSlice";
import profileSlice from "./slices/profileSlice";
import backlogSlice from "./slices/backlogSlice";
import assignMemberSlice from './slices/assignMemberSlice';
import roleSlice from "./slices/roleSlice";
import teamSlice from './slices/teamsSlice'
import viewAllIssueSlice  from "./slices/viewAllIssuesSlice";

const rootReducer = {
    global : globalSlice,
    project : projectSlice,
    issue : issueSlice,
    activeSprint : activeSprintSlice,
    profile : profileSlice,
    backlog : backlogSlice,
    assignMember: assignMemberSlice,
    role: roleSlice,
    team: teamSlice,
    viewAllIssue: viewAllIssueSlice
}

export default rootReducer